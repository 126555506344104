//项目配置可将一些公共的配置放在这个文件中.

// 项目 多语言配置
export const locales = ['en', 'zh-CN', 'ko', 'ja', 'zh-TW', 'ru', 'es', 'vi'];

// 切换仓位模式  不再提示 localStroge 存储的变量名
export const localChangeContractModeNeverName = 'weex:change:contract:mode';

// 下单时 数量的单位
export const localSwitchContractOrderUnitNeverName = 'weex:switch:contract:order:unit';
export const localSwitchContractOrderNewUnitNeverName = 'weex:switch:newcontract:order:unit';

// 资产页面 隐藏小额资产
export const localHideSmallAsset = 'weex:hide:small:asset';

// 移动委托标记修改提示
export const localMoveModifyNeverName = 'weex:move:modify';

// 开通跟单协议
export const localOpenFollow = 'weex:open:follow';

// 开通跟单协议
export const localOpenCopyFollow = 'weex:open:copy_follow';

// 主站（币币+合约） 混合合约 统一主题
export const SUPPORTED_THEME = ['black', 'white'];

// 显示当前交易币种
export const localShowCurrentCurrency = 'weex:show:currency';

// 交易分离部分页面路径
export const TRADE_PAGE_PATH = [
    'swap', // 合约U本位
    'delivery', // 合约币本位
    'game', // 合约模拟盘
    'swapnew', // 合约Pro
    'progame', // 合约Pro 模拟盘
    'contractProIntroduction',  // 合约Pro 介绍页
    'contract',  //资金费率｜保证金流水｜仓位档位
    'trade',  // 现货
    'kline',  // 行情
    'copytrading',  // 跟单模块
    'asset',  // 资产模块
    'record',  // 财务记录模块
    'transac',  // 交易记录模块
];
