// 登录后，发送短信验证码类型
export const config = {
  sendSmsLogin: {
    modifyPwd: 6, // 修改密码
    createApi: 1, // 创建API
    modifyApi: 37, // 编辑API
    delApi: 36, // 删除API
    bindMobile: 2, // 绑定手机
    modifyMobile: 38, // 修改手机
    bindEmail: 25, // 绑定邮箱
    bindGoogle: 27, // 绑定谷歌验证
    modifyGoogle: 21, // 修改谷歌验证
    coin: 5, // 虚拟币提币
    address: 8, // 提币地址设置
    cancelCoin: 388, // 取消提币
    verify: 101, // 安全验证
    setFundPwd: 404, // 设置资金密码
    modifyFundPwd: 405, // 修改资金密码
    resetFundPwd: 406, // 重置资金密码
    setTradeTypeForFund: 410, // 重置资金密码交易类型
    withdrawFundPwd: 404, // 提币验证资金密码
    internalWithdraw: 391, // 内部转账
    antiPhishingCode: 414, // 设置防钓鱼码
    antiPhishingCodeModify: 415 // 修改防钓鱼码
  },
  sendEmailLogin: {
    modifyPwd: 6, // 修改密码
    createApi: 26, // 创建API
    modifyApi: 43, // 编辑API
    delApi: 42, // 删除API
    bindMobile: 40, // 绑定手机
    modifyMobile: 41, // 修改手机
    bindEmail: 24, // 绑定邮箱
    bindGoogle: 25, // 绑定谷歌验证
    modifyGoogle: 21, // 修改谷歌验证
    coin: 5, // 虚拟币提币
    address: 8, // 提币地址设置
    cancelCoin: 389, // 取消提币
    verify: 1, // 安全验证
    setFundPwd: 404, // 设置资金密码
    modifyFundPwd: 405, // 修改资金密码
    resetFundPwd: 406, // 重置资金密码
    setTradeTypeForFund: 410, // 重置资金密码交易类型
    withdrawFundPwd: 404, // 提币验证资金密码
    internalWithdraw: 391, // 内部转账
    antiPhishingCode: 414, // 设置防钓鱼码
    antiPhishingCodeModify: 415 // 修改防钓鱼码
  },
  error: {
    SUCCESS: '00000', // 成功
    PHONE_FORMAT_ERROR: '02018', // 手机号码格式错误
    USER_HAS_BIND_PHONE_ERROR: '02019', // 用户已经绑定手机
    PHONE_EXIST_EROOR: '02020', // 手机号已存在
    EMAIL_BE_BIND_ERROR: '02021', // 邮箱已被绑定
    EMAIL_EXIST_ERROR: '02022', // 邮箱已存在
    PHONE_BE_BIND_ERROR: '02024', // 手机号已被绑定
    SEND_SMS_ERROR: '02025', // 发送短信异常
    EMAIL_NOT_NULL_ERROR: '02030', // 邮箱不能为空
    SEND_MAIL_FAILED: '02031', // 验证码已发送，请勿重复操作！
    VALIDATE_PASSWRD_NOT_SAME: '02036', // 原始密码错误
    PHONE_VALIDATE_CODE_ERROR: '02037', // 短信验证码不正确
    EMAIL_VALIDATE_CODE_ERROR: '02038', // 邮箱验证码不正确
    GOOGLE_VALIDATE_CODE_ERROR: '02039', // 谷歌验证码不正确
    ORDER_BORROW_BALANCE_ERROR: '031017', // 杠杆账户-全部还币时余额不足
    CUSTOM_EXCEPTION_ERROR: '22222', // 区分不了的异常,
    SYSTEM_MAINTENANCE: 'system_maintenance', // 系統維護
    ERROR: '99999', // 错误
    STOP_PROFIT_PRICE_MORE_THAN_ERROR: '60001', // 止盈价格需要大于%
    STOP_PROFIT_PRICE_LESS_THAN_ERROR: '60002', // 止盈价格需要小于%
    STOP_LOSS_PRICE_MORE_THAN_ERROR: '60003', // 止损价格需要大于%
    STOP_LOSS_PRICE_LESS_THAN_ERROR: '60004', // 止损价格需要小于%
    POSITION_COPY_CLOSE_ERROR: '05008', // 带单品种不平仓
    ACTIVITY_ONLY_AGENT_USER: '51001', // 运营活动 - 代理用户
    ACTIVITY_NO_AGENT_USER: '51005', // 运营活动 - 自然用户 （非自然用户报名活动触发）
    ACTIVITY_NO_OLD_USER: '51010', // 运营活动 - 新用户
  },
  // 币币二期重构后 提币 添加提币地址 错误code
  errorCode: {
    PHONE_VALIDATE_CODE_ERROR: '20409', // 短信验证码不正确
    EMAIL_VALIDATE_CODE_ERROR: '20422', // 邮箱验证码不正确
    GOOGLE_VALIDATE_CODE_ERROR: '20555', // 谷歌验证码不正确
    FUND_ERROR: '20423' // 资金密码错误
  },

  // 账户实名认证状态
  hasRealValidate: {
    NO_SUBMIT: 0, // 未认证
    NO_AUDIT: 1, // 待审核
    AUDIT_PASS: 2, // 审核通过
    AUDIT_REJECT: 3 // 审核驳拒绝
  },

  // 1谷歌2手机3邮箱
  checkSecurityForBind: {
    GOOGLE: 1,
    MOBILE: 2,
    EMAIL: 3
  },

  // 站点 id
  site: {
    LEVER_ID: 2,
    NEW_CONTRACT_ID: 8,
    COIN_ID: 1,
    CONTRACT_ID: 3,
    OTC_ID: 4
  },

  // OTC 订单状态  1待付款 2待收款 3待收币 4待放币 5已完成 6已取消 7申诉中
  otcOrderStatus: {
    payment: 1,
    collection: 2,
    closedCoin: 3,
    putCoin: 4,
    completed: 5,
    cancelled: 6,
    appeal: 7
  },
  // 混合合约业务线配置
  businessLine: {
    USDT: 10,
    MIX: 11,
    SUSDT: 8,
    SMIX: 9
  }
};

export const SITE_ACCOUNT_MAP = {
  10001: 1,
  10002: 2
};

// 委托方向的枚举
export const ENTRUST_DIRECTION_MAP = {
  '1': 'open_long', // 开多
  '2': 'open_short',
  '3': 'close_long', // 平多
  '4': 'close_short',
  '5': 'adl_long', // 减仓平多
  '6': 'adl_short',
  '9': 'burst_long', // 爆仓平多
  '10': 'burst_short',
  // '9': 'burst_long', // 爆仓协议平多,
  // '10': 'burst_short',
  '7': 'protocol_long', // 协议平多
  '8': 'protocol_short'
};

// 用户重构后，在登录后发送的验证码枚举 -- 获取验证类型的action
export const VERIFY_CODE_ACTION = {
  bindMobile: 3031, // 绑定手机
  modifyMobile: 3032, // 修改手机
  modifyLoginPwd: 3023, // 修改登录密码
  bindEmail: 3041, // 绑定邮箱
  antiPhishingCode: 3044, // 设置/修改防钓鱼码
  bindGoogle: 3051, // 绑定谷歌
  modifyGoogle: 3052, // 修改谷歌
  setTradePwd: 3061, // 设置资金密码
  modifyTradePwd: 3063, // 修改资金密码
  findTradePwd: 3062, // 找回资金密码
  updateTradePwdRule: 3066, // 修改资金密码规则
  createAPI: 3071, // 创建 API
  modifyAPI: 3073, // 修改 API
  deleteAPI: 3074, // 删除 API
  viewAPI: 3072, // 查看 API 详情
  withdraw_action: 3104, // 提币
  add_withdraw_address_action: 3105 // 添加提币地址
};

// 使用新版发送验证的枚举
export const VERIFY_CODE_KEY = {
  SMS: {
    LOGIN: 'LOGIN_STEP2_AUTH_PHONE', // 登录
    REGISTER: 'REGISTER_MOBILE', // 注册
    FORGET_LOGIN_PWD: 'LOGIN_PWD_RESET_PHONE', // 忘记登录密码
    BIND_MOBILE: 'MOBILE_BIND_V_PHONE', // 绑定手机
    MODIFY_MOBILE: 'MOBILE_MODIFY_V_OLD_PHONE', // 修改手机
    BIND_NEW_MOBILE: 'MOBILE_MODIFY_V_NEW_PHONE', // 修改手机-新手机
    MODIFY_LOGIN_PWD: 'LOGIN_PWD_MODIFY_PHONE', // 修改登录密码
    BIND_EMAIL: 'EMAIL_BIND_V_PHONE', // 绑定邮箱
    ANTI_PHISHING_CODE: 'EMAIL_ANTI_CODE_PHONE', // 设置/修改防钓鱼码
    BIND_GOOGLE: 'GOOGLE_CODE_BIND_PHONE', // 绑定谷歌
    MODIFY_GOOGLE: 'GOOGLE_CODE_RESET_PHONE', // 修改谷歌
    SET_TRADE_PWD: 'TRADE_PWD_SET_PHONE', // 设置资金密码
    MODIFY_TRADE_PWD: 'TRADE_PWD_MODIFY_PHONE', // 修改资金密码
    FORGET_TRADE_PWD: 'TRADE_RESET_PWD_PHONE', // 找回资金密码
    UPDATE_TRADE_PWD_RULE: 'TRADE_PWD_RULE_MODIFY_PHONE', // 修改资金密码规则
    CLOSE_SMS: 'MOBILE_DISABLE_PHONE', // 关闭短信
    OPEN_SMS: 'MOBILE_ENABLE_PHONE', // 开启短信
    CLOSE_EMAIL: 'EMAIL_DISABLE_PHONE', // 关闭邮箱
    OPEN_EMAIL: 'EMAIL_ENABLE_PHONE', // 开启邮箱
    CLOSE_GOOGLE: 'GOOGLE_DISABLE_PHONE', // 关闭谷歌
    OPEN_GOOGLE: 'GOOGLE_ENABLE_PHONE', // 开启谷歌
    MODIFY_VERIFICATION: 'LOST_SAFETY_V_OLD_PHONE', // 修改验证项
    NEW_MODIFY_VERIFICATION: 'LOST_SAFETY_V_NEW_PHONE', // 修改新验证项
    WITHDRAW_VERIFICATION: 'SPOT_WITHDRAW_PHONE', // 提币验证
    ADD_WITHDRAW_VERIFICATION: 'SPOT_ADD_WITHDRAW_ADDRESS_PHONE', // 添加提币地址验证
    NEW_DEVICE: 'USER_DEVICE_AUTHORIZE_NEW_DEVICE_PHONE' // 新设备
  },
  EMAIL: {
    LOGIN: 'LOGIN_STEP2_AUTH_MAIL', // 登录
    REGISTER: 'REGISTER_EMAIL', // 注册
    FORGET_LOGIN_PWD: 'LOGIN_PWD_RESET_MAIL', // 忘记登录密码
    BIND_MOBILE: 'MOBILE_BIND_V_MAIL', // 绑定手机
    MODIFY_MOBILE: 'MOBILE_MODIFY_V_MAIL', // 修改手机
    MODIFY_LOGIN_PWD: 'LOGIN_PWD_MODIFY_MAIL', // 修改登录密码
    BIND_EMAIL: 'EMAIL_BIND_V_NEW_MAIL', // 绑定邮箱
    ANTI_PHISHING_CODE: 'EMAIL_ANTI_CODE_MAIL', // 设置/修改防钓鱼码
    BIND_GOOGLE: 'GOOGLE_CODE_BIND_MAIL', // 绑定谷歌
    MODIFY_GOOGLE: 'GOOGLE_CODE_RESET_MAIL', // 修改谷歌
    SET_TRADE_PWD: 'TRADE_PWD_SET_MAIL', // 设置资金密码
    MODIFY_TRADE_PWD: 'TRADE_PWD_MODIFY_MAIL', // 修改资金密码
    FORGET_TRADE_PWD: 'TRADE_RESET_PWD_MAIL', // 找回资金密码
    UPDATE_TRADE_PWD_RULE: 'TRADE_PWD_RULE_MODIFY_MAIL', // 修改资金密码规则
    CLOSE_SMS: 'MOBILE_DISABLE_MAIL', // 关闭短信
    OPEN_SMS: 'MOBILE_ENABLE_MAIL', // 开启短信
    CLOSE_EMAIL: 'EMAIL_DISABLE_MAIL', // 关闭邮箱
    OPEN_EMAIL: 'EMAIL_ENABLE_MAIL', // 开启邮箱
    CLOSE_GOOGLE: 'GOOGLE_DISABLE_MAIL', // 关闭谷歌
    OPEN_GOOGLE: 'GOOGLE_ENABLE_MAIL', // 开启谷歌
    MODIFY_VERIFICATION: 'LOST_SAFETY_V_OLD_MAIL', // 修改验证项
    NEW_MODIFY_VERIFICATION: 'LOST_SAFETY_V_NEW_MAIL', // 修改新验证项
    WITHDRAW_VERIFICATION: 'SPOT_WITHDRAW_EMAIL', // 提币验证
    ADD_WITHDRAW_VERIFICATION: 'SPOT_ADD_WITHDRAW_ADDRESS_EMAIL', // 添加提币地址验证
    NEW_DEVICE: 'USER_DEVICE_AUTHORIZE_NEW_DEVICE_EMAIL' // 新设备
  }
};

// 使用新版发送验证的枚举 - new
export const VERIFY_CODE_VAL = {
  SMS: {
    LOGIN: '0000', // 登录
    REGISTER: '3011', // 注册
    FORGET_LOGIN_PWD: '3024', // 忘记登录密码
    BIND_MOBILE: '3031', // 绑定手机
    MODIFY_MOBILE: '3032', // 修改手机
    BIND_NEW_MOBILE: '3032', // 修改手机-新手机
    MODIFY_LOGIN_PWD: '3023', // 修改登录密码
    BIND_EMAIL: '3041', // 绑定邮箱
    ANTI_PHISHING_CODE: '3044', // 设置/修改防钓鱼码
    BIND_GOOGLE: '3051', // 绑定谷歌
    MODIFY_GOOGLE: '3052', // 修改谷歌
    SET_TRADE_PWD: '3061', // 设置资金密码
    MODIFY_TRADE_PWD: '3063', // 修改资金密码
    FORGET_TRADE_PWD: '3062', // 找回资金密码
    UPDATE_TRADE_PWD_RULE: '3066', // 修改资金密码规则
    CLOSE_SMS: '3100', // 关闭短信
    OPEN_SMS: '3097', // 开启短信
    CLOSE_EMAIL: '3098', // 关闭邮箱
    OPEN_EMAIL: '3101', // 开启邮箱
    CLOSE_GOOGLE: '3102', // 关闭谷歌
    OPEN_GOOGLE: '3099', // 开启谷歌
    MODIFY_VERIFICATION: '3111', // 修改验证项
    NEW_MODIFY_VERIFICATION: '3112', // 修改新验证项
    WITHDRAW_VERIFICATION: '3108', // 提币验证
    ADD_WITHDRAW_VERIFICATION: '3110', // 添加提币地址验证
    NEW_DEVICE: '0000', // 新设备
    OAUTH_SMS: '3117', // 2FA验证
    OAUTH_EMAIL: '3117', // 2FA验证
    OAUTH_GOOGLE: '3117' // 2FA验证
  },
  EMAIL: {
    LOGIN: '0000', // 登录
    REGISTER: '3012', // 注册
    FORGET_LOGIN_PWD: '3024', // 忘记登录密码
    BIND_MOBILE: '3031', // 绑定手机
    MODIFY_MOBILE: '3032', // 修改手机
    MODIFY_LOGIN_PWD: '3023', // 修改登录密码
    BIND_EMAIL: '3041', // 绑定邮箱
    ANTI_PHISHING_CODE: '3044', // 设置/修改防钓鱼码
    BIND_GOOGLE: '3051', // 绑定谷歌
    MODIFY_GOOGLE: '3052', // 修改谷歌
    SET_TRADE_PWD: '3061', // 设置资金密码
    MODIFY_TRADE_PWD: '3063', // 修改资金密码
    FORGET_TRADE_PWD: '3062', // 找回资金密码
    UPDATE_TRADE_PWD_RULE: '3066', // 修改资金密码规则
    CLOSE_SMS: '3100', // 关闭短信
    OPEN_SMS: '3097', // 开启短信
    CLOSE_EMAIL: '3098', // 关闭邮箱
    OPEN_EMAIL: '3101', // 开启邮箱
    CLOSE_GOOGLE: '3102', // 关闭谷歌
    OPEN_GOOGLE: '3099', // 开启谷歌
    MODIFY_VERIFICATION: '3113', // 修改验证项
    NEW_MODIFY_VERIFICATION: '3114', // 修改新验证项
    WITHDRAW_VERIFICATION: '3107', // 提币验证
    ADD_WITHDRAW_VERIFICATION: '3109', // 添加提币地址验证
    NEW_DEVICE: '0000', // 新设备
    OAUTH_SMS: '3117', // 2FA验证
    OAUTH_EMAIL: '3117', // 2FA验证
    OAUTH_GOOGLE: '3117' // 2FA验证
  }
};

export const DirectionsColorMap = {
  1: 'green',
  2: 'red',
  3: 'red',
  4: 'green',
  5: 'red',
  6: 'green',
  7: 'red',
  8: 'green',
  9: 'red',
  10: 'green',
  11: 'red',
  12: 'green'
};

Object.freeze(config);

// 验证错误码
export const ValidateErrorCode = {
  phone: '20409', // 短信验证码不正确
  email: '20422', // 邮箱验证码不正确
  google: '20555', // 谷歌验证码不正确
  fund: '20423' // 资金密码错误
};

// 运营活动相关常量
export const ActivityEnumData = {
  taskStage: {
    NOT_START: 'NOT_START', //未开始
    IN_PROGRESS: 'IN_PROGRESS', //进行中
    FINISHED: 'FINISHED', //已结束
  },
  taskStatus: {
    DRAFT: 'DRAFT', //草稿
    ONLINE: 'ONLINE', //上线
    OFFLINE: 'OFFLINE', //下线
    DELETED: 'DELETED', //删除
  },
  taskType: {
    BEGINNER_TASK: 'BEGINNER_TASK', // 新手任务
    TRADING_COMPETITION: 'TRADING_COMPETITION', // 个人交易赛
    FIREWORKS_TASK:'FIREWORKS_TASK'// fireworks
  },
  taskItemType: {
    REGISTER_PASS: 'REGISTER_PASS', //完成注册
    KYC_PASS: 'KYC_PASS', //kyc通过
    TRANSFER_AND_TRADING: 'TRANSFER_AND_TRADING', //划转及交易
    TRADING_VOLUME: 'TRADING_VOLUME', //交易
    RECHARGE: 'RECHARGE', //充值
  },
  userTaskType: { // 用户完成任务状态
    PENDING: 'PENDING', // 未完成
    COMPLETED: 'COMPLETED', // 已完成
    GRANTED: 'GRANTED', // 已发放
    UNSATISFIED: 'UNSATISFIED', // 未满足
  },
  bonusPoolType: { // 奖池类型
    FIXED: 'FIXED', //固定
    DYNAMIC: 'DYNAMIC', //动态
  }
}

// 二维码类型
export const QrTypeEnumData = {
  login: 'login'
}

// 不同语言对应的值
export const LanguageType = { 
  en: '0',
  ja: '2', 
  ko: '3', 
  vi: '4', 
  'zh-TW': '5', 
  'zh-CN': 1 
}
//不同语言
export const LanguageChineseType = { 
  en: '英文',
  ko: '韩文', 
  vi: '越南语', 
  'zh-TW': '繁体', 
  'zh-CN': '中文' 
}
// export default {config, SITE_ACCOUNT_MAP, ENTRUST_DIRECTION_MAP, VERIFY_CODE_ACTION, VERIFY_CODE_KEY,DirectionsColorMap}
