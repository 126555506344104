module.exports = {
  baseUrl: 'https://www.weex.com',
  webSocketUrl: "wss://stg-stream-rest.weex.tech/v1/stream",
  // newWebSocketUrl: "wss://csocket.thewebsocket.com/websocket",
  //newWebSocketUrl: "wss://contractsocket-ucloud.9ibp.com/websocket",
  newWebSocketUrl: "wss://stg-contract-websocket.weex.tech/websocket",
  newMixSocketUrl: 'wss://stg-stream-rest.weex.tech/v1/stream', // 新增地址
  backupSocketUrl: [
    {
      name: "new-prod-1",
      //socketUrl: "wss://contractsocket.9ibp.com/websocket"
      socketUrl: "wss://stg-contract-websocket.weex.tech/websocket"
    },
    {
      name: "new-prod-2",
      //socketUrl: "wss://contractsocket-ucloud.9ibp.com/websocket"
      socketUrl: "wss://stg-contract-websocket.weex.tech/websocket"
    }
  ],
  bbBackupSocketUrl: [
    {
      name: "backup1",
      //url: "wss://stream.weex.com/v1/stream"
      url: "wss://stg-stream-rest.weex.tech/v1/stream"
    },
    {
      name: "backup2",
      //url: "wss://stream.weex.me/v1/stream"
      url: "wss://stg-stream-rest.weex.tech/v1/stream"
    },
    {
      name: "backup3",
      //url: "wss://stream.weex.site/v1/stream"
      url: "wss://stg-stream-rest.weex.tech/v1/stream"
    }
  ],
  bbSocketReport: false, // 币币websocket是否开启上报
  socketReport: true, // 2020 1 6 本字段更新为：合约使用websocket是否进行测速，true使用backupSocketUrl，否则使用newWebSocketUrl
  socketCompress: true, // 是否开启websocket压缩，目前只有合约支持
  bbSocketCompress: true, // 币币交易websocket开启压缩
  retryTime: 28800, // 8小时
  oldUrl: "https://www.weex.com",
  env: 'online'
}
