// 请求函数的 国际化配置
// 语言类型 0英文 1中文 2日文 3韩文
import LocalCookie, { CookieKey } from '~/utils/localCookie.js'
let con = {
  'zh-CN': {
    'tip': '提示',
    'timeout': '请求超时!',
    'expired': '登录过期,请重新登录',
    'loginvalid': '请登录访问',
    'unknow': '系统繁忙',
    'notfound': '请求错误',
    'forbidden': '请求被禁止',
    'lang': 1,
    'locale': 'zh-CN/',
    'wsErr1007': '您电脑时间与实时时间戳时差过大，请您至电脑设定开启自动时区',
    'resetBtn': '重试'
  },
  'en': {
    'tip': 'Tip',
    'timeout': 'Request timed out!',
    'expired': 'Login expired, please log in again',
    'loginvalid': 'Please login to visit',
    'unknow': 'The system is busy',
    'notfound': 'Request error',
    'forbidden': 'Request is forbidden',
    'lang': 0,
    'locale': 'en/',
    'wsErr1007': 'Computer time is different from real-time timestamp, please enable auto time zone setting in your computer preferences.',
    'resetBtn': 'Retry'
  },
  'ko': {
    'tip': '주의사항',
    'timeout': '연결시간 초과!',
    'expired': '로그인이 만료되었습니다. 다시 로그인하세요',
    'loginvalid': '로그인이 만료되었습니다. 다시 로그인하세요',
    'unknow': '시스템 오류',
    'notfound': '요청 오류',
    'forbidden': '요청이 금지되어있다.',
    'lang': 3,
    'locale': 'ko/',
    'wsErr1007': 'Computer time is different from real-time timestamp, please enable auto time zone setting in your computer preferences.',
    'resetBtn': 'Retry'
  },
  'ja': {
    'tip': '提示',
    'timeout': '請求時間が切れました!',
    'expired': 'ログイン時間が切れました。もう一度ログインください',
    'loginvalid': 'ログインしてからご覧になれます',
    'unknow': 'システム渋滞です',
    'notfound': '請求エラー',
    'forbidden': '請求禁止',
    'lang': 2,
    'locale': 'ja/',
    'wsErr1007': 'コンピューターの時間とリアルタイムのタイムスタンプとの差が大きいため、コンピューターの設定で自動タイムゾーンを有効にしてください。',
    'resetBtn': '再試行'
  },
  'vi': {
    'timeout': 'Yêu cầu đã hết thời gian chờ!',
    'expired': 'Đăng nhập hết hạn, vui lòng đăng nhập lại',
    'loginvalid': 'Vui lòng đăng nhập để truy cập',
    'unknow': 'Hệ thống đang bận',
    'notfound': 'Yêu cầu lỗi',
    'forbidden': 'Yêu cầu bị cấm',
    'lang': 4,
    'locale': 'vi/',
    'wsErr1007': 'Thời gian máy tính của bạn chêch lệch quá nhiều so với dấu thời gian thực, vui lòng bật cài đặt múi giờ tự động trên máy tính của bạn.',
    'resetBtn': 'Thử lại'
  },
  'zh-TW': {
    'tip': '提示',
    'timeout': '請求超時!',
    'expired': '登錄過期,請重新登錄',
    'loginvalid': '請登錄訪問',
    'unknow': '系統繁忙',
    'notfound': '請求錯誤',
    'forbidden': '請求被禁止',
    'lang': 5,
    'locale': 'zh-TW/',
    'wsErr1007': '您電腦時間與實時時間戳時差過大，請您至電腦設定開啟自動時區',
    'resetBtn': '重試'
  },
  'ru': {
    'tip': 'Подсказки',
    'timeout': 'Истекло время запроса!',
    'expired': 'Срок входа истек, войдите снова',
    'loginvalid': 'Пожалуйста, войдите, чтобы посетить',
    'unknow': 'Система занята',
    'notfound': 'Ошибка запроса',
    'forbidden': 'Запрос запрещен',
    'lang': 6,
    'locale': 'ru/',
    'wsErr1007': 'Время на вашем компьютере значительно отличается от метки реального времени. Пожалуйста, включите автоматическую настройку часового пояса в настройках компьютера.',
    'resetBtn': 'Повторить'
  },
  'es': {
    'tip': 'Sugerencia',
    'timeout': '¡Solicitud agotada!',
    'expired': 'Inicio de sesión caducado, inicie sesión de nuevo',
    'loginvalid': 'Inicie sesión para visitar',
    'unknow': 'El sistema está ocupado',
    'notfound': 'Error de solicitud',
    'forbidden': 'La solicitud está prohibida',
    'lang': 7,
    'locale': 'es/',
    'wsErr1007': 'La hora de su computadora difiere significativamente de la marca de tiempo en tiempo real. Por favor, habilite la zona horaria automática en la configuración de su computadora.',
    'resetBtn': 'Reintentar'
  },
}

module.exports = function (lang) {
  let localLang = lang || LocalCookie.get(CookieKey.LANG) || 'en'
  if (localLang === 'zh-cn') {
    localLang = 'zh-CN'
  }
  return con[localLang]
}
